import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Grid,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  TextField,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Button,
} from "@mui/material";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import TableList from "./table-events";
import Navbar from "../../components/navbar";
import BreadcrumbDouble from "../../components/breadcrumb/double";
import "./notifications.scss";
import { setEventLoading } from "../../redux/reducers/events";
import { updatePromotionalOffer } from "../../redux/reducers/offers";
import {
  addNotification,
  deleteNotification,
  editNotification,
} from "../../redux/reducers/notification";
import Swal from "sweetalert2";
import api, { Notification_APIS } from "../../config/api-config";
import { useSortableData } from "../../components/SortTable";
import Loader from "../../components/loader";
const Notifications = () => {
  const dispatch = useDispatch();
  const [formData, setformData] = useState({
    title: "",
    message: "",
    notificationDate: "",
    notificationTime: "",
    offerLink: null,
    outletLink: null,
    type: null,
    pushNotification: true,
    internalNotification: true,
    notificationToFemale: false,
    notificationToResident: false,
    zone: new Date().getTimezoneOffset().toString(),
  });

  const [eventData, setEventData] = useState([]);
  const [outletData, setOutletData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [searchArry, setsearchArry] = useState([]);
  const [search, setsearch] = useState("");
  const [promotional, setPromotional] = useState([]);
  const [outletPromotional, setOutletPromotional] = useState([]);
  const [change, setChange] = useState(false);
  const {isLoading } = useSelector(
    (store) => ({
      isLoading: store.events.isLoading,
    }),
    shallowEqual
  );
  const [notificationList, setnotificationList] = useState([]);
  useEffect(() => {
    fetchNotificationList();
    fetchEvents();
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   events?.map((event) => {
  //     if (event?.offer?.isPromotional) {
  //       setPromotional({ label: `${event?.eventName} - ${event?.offer?.offerName}`, id: event?.offer?.id })
  //     }
  //   })
  // }, [events])
  const fetchNotificationList = async () => {
    try {
      const res = await api.get(Notification_APIS.getAllNoti);
      if (res.data.status) {
        setsearchArry(res.data.data);
        setnotificationList(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const fetchEvents = async () => {
    try {
      dispatch(setEventLoading(true));
      const res = await api.get(Notification_APIS.events);
      // Check if response and necessary data keys exist
      if (res && res.data && res.data.status && res.data.data) {
        const eventsData = [];
        const outletsData = [];
        const events = res?.data?.data?.events ?? [];
        const outlets = res?.data?.data?.outlets ?? [];
  
        if (Array.isArray(events) && events.length > 0) {
          for (const event of events) {
            if (event?.eventName && event?.offer?.offerName && event?.offer?.id) {
              eventsData.push({
                label: `${event.eventName} - ${event.offer.offerName}`,
                id: event.offer.id,
              });
              // Check if promotional offer exists
              if (event.offer.isPromotional) {
                setPromotional({
                  label: `${event.eventName} - ${event.offer.offerName}`,
                  id: event.offer.id,
                });
              }
            }
          }
          setEventData(eventsData ?? []);
        }
  
        if (Array.isArray(outlets) && outlets.length > 0) {
          for (const outlet of outlets) {
            if (outlet?.outletName && outlet?.id) {
              outletsData.push({
                label: `${outlet.outletName}`,
                id: outlet.id,
              });
              // Check if promotional outlet exists
              if (outlet.isPromotional) {
                setOutletPromotional({
                  label: `${outlet.outletName}`,
                  id: outlet.id,
                });
              }
            }
          }
          setOutletData(outletsData ?? []);
        }
      }
      dispatch(setEventLoading(false));
    } catch (error) {
      dispatch(setEventLoading(false));
      console.log("error: ", error);
    }
  };
  
  // const State = useSelector((state) => state.notificationReducer);
  const _submit = () => {
    if (
      !formData.message ||
      !formData.notificationDate ||
      !formData.notificationTime
    ) {
      Swal.fire("error", "Message, date, time and offer are required", "error");
      return;
    }

    if (!(formData.offerLink || formData.outletLink || formData.title)) {
      Swal.fire("error", "Please select offer or fill title field.", "error");
      return;
    }
    dispatch(
      edit ? editNotification(formData, editId) : addNotification(formData)
    );
    // if (State.error) {
    //   Swal.fire("error", "Required All field", "error");
    // } else {
    Swal.fire(
      "Success",
      edit
        ? "Notification edit successfully"
        : "New notification added successfully.",
      "success"
    );
    setformData({
      title: "",
      message: "",
      notificationDate: "",
      notificationTime: "",
      offerLink: null,
      type: null,
      pushNotification: true,
      internalNotification: true,
      notificationToFemale: false,
      notificationToResident: false,
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    // }
  };
  const { items, requestSort, sortConfig } = useSortableData(searchArry);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const handleSearch = (e) => {
    if (search === "") setsearchArry([...notificationList]);
    else {
      let filterData1 = searchArry.filter((item) => {
        return item?.offer?.offerName
          ?.toLowerCase()
          .includes(search.toLowerCase());
      });
      let filterData2 = searchArry.filter((item) => {
        return item?.message?.toLowerCase().includes(search.toLowerCase());
      });
      let filterData3 = searchArry.filter((item) => {
        return item?.eventName?.toLowerCase().includes(search.toLowerCase());
      });
      let cpy = [...filterData1, ...filterData2, ...filterData3];
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      setsearchArry(cpy.filter(onlyUnique));
    }
  };

  const handleDelete = (item) => {
    // console.log('item: ', item);
    // dispatch(deleteNotification({ id: item?.id }));
    dispatch(deleteNotification(item?.id));
    // if (State.error) {
    //   Swal.fire("error", "Required All field", "error");
    // } else {
    Swal.fire("Success", "Notification delete successfully.", "success");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    // }
  };

  const handleEdit = (item) => {
    setEdit(true);
    setEditId(item?.id);
    window.scrollTo(500, 0);
    setformData({
      title: item?.title,
      message: item?.message,
      notificationDate: item?.notificationDate,
      notificationTime: item?.notificationTime,
      offerLink: item?.offerLink,
      outletLink: item?.outletLink,
      type: item?.type,
      pushNotification: true,
      internalNotification: true,
      notificationToFemale: false,
      notificationToResident: false,
      zone: item?.zone,
    });
  };
  const setAsPromotional = async () => {
    if (promotional && promotional?.id) {
      let response = await dispatch(
        updatePromotionalOffer({
          id: promotional?.id,
          type: "DEAL",
        })
      );
      if (response?.status) {
        Swal.fire(
          "Success",
          "Promotional offer added successfully.",
          "success"
        );
      }
    } else {
      if (outletPromotional && outletPromotional?.id) {
        let response = await dispatch(
          updatePromotionalOffer({
            id: outletPromotional?.id,
            type: "OUTLET",
          })
        );
        if (response?.status) {
          Swal.fire(
            "Success",
            "Promotional offer added successfully.",
            "success"
          );
        }
      }
    }
    setChange(false);
  };
  return (
    <div>
      <Navbar />
      {isLoading && <Loader />}
      <BreadcrumbDouble
        breadFirst="Home"
        breadLast="Notifications"
        linkFirst="/"
      />
      <div className="container-main ">
        <h2 className="header mb3 ml3" style={{ fontWeight: 500 }}>
          Set a promotional pop up
        </h2>
        <Grid
          item
          container
          xs={12}
          sm={12}
          lg={12}
          spacing={2}
          className="m-auto w-100"
          sx={{
            flexWrap: "nowrap",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" }, // Change flex direction responsively
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            spacing={2}
            className="w-100"
            sx={{
              flexDirection: "column", // Change flex direction responsively
            }}
          >
            <Grid item>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={eventData}
                defaultValue={promotional}
                getOptionLabel={(option) => {
                  return option?.label ?? ''
                }}
                value={promotional}
                onChange={(event, value) => {
                  setOutletPromotional([]);
                  setPromotional(value);
                  setChange(true);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Deal" />
                )}
              />
            </Grid>
            <p className="text-center m-0 mt-3">OR</p>
            <Grid item>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={outletData}
                defaultValue={outletPromotional}
                getOptionLabel={(option) => {
                 return option?.label ?? ''
                }}
                value={outletPromotional}
                onChange={(event, value) => {
                  setPromotional([]);
                  setOutletPromotional(value);
                  setChange(true);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Listing" />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <button
              className="btn-primary-square"
              onClick={setAsPromotional}
              disabled={!change}
            >
              CONFIRM
            </button>
          </Grid>
        </Grid>
        <h2 className="header mb3 mt3 ml3">Create Notification</h2>
        <Grid
          item
          container
          xs={12}
          sm={12}
          lg={8}
          spacing={2}
          className="m-auto text-area-containers"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="m-auto text-area-container"
          >
            <div className="flex relative">
              <input
                type="text"
                value={formData.title}
                onChange={(evt) =>
                  setformData({ ...formData, title: evt.target.value })
                }
                placeholder="Title"
                className="w-100 input-square"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="text-area-container"
          >
            <TextareaAutosize
              minRows={8}
              className="textarea"
              aria-label="maximum height"
              placeholder="Message"
              value={formData.message}
              onChange={(evt) =>
                setformData({ ...formData, message: evt.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="flex w-100 relative">
              <input
                type="date"
                placeholder="Send date"
                className="w-100 input-square"
                value={formData.notificationDate}
                onChange={(evt) =>
                  setformData({
                    ...formData,
                    notificationDate: evt.target.value,
                  })
                }
              />
              {/*<span className="cl-icon absolute"><DateRange/></span>*/}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="text-area-container"
          >
            {/* {<div className="flex w-100 relative">} */}
            <input
              type="time"
              placeholder="Send time "
              className="w-100 input-square"
              value={formData?.notificationTime}
              onChange={(e) => {
                setformData({
                  ...formData,
                  notificationTime: e.target.value,
                });
              }}
            />
            {/*<span className="cl-icon absolute"><AccessTime/> </span>*/}
            {/* {</div>} */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="text-area-container"
          >
            <FormControlLabel
              className="checkbox mb2 pl3"
              control={
                <Checkbox
                  defaultChecked
                  icon={
                    <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                  }
                  checkedIcon={<img src="/images/chck.svg" alt="check" />}
                />
              }
              label="Make this a push notification"
              value={formData.pushNotification}
              onChange={(evt) =>
                setformData({
                  ...formData,
                  pushNotification: !formData.pushNotification,
                })
              }
            />

            <FormControlLabel
              className="checkbox mb2 pl3"
              control={
                <Checkbox
                  defaultChecked
                  icon={
                    <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                  }
                  checkedIcon={<img src="/images/chck.svg" alt="check" />}
                />
              }
              label="Make this an internal notification"
              value={formData.internalNotification}
              onChange={(evt) =>
                setformData({
                  ...formData,
                  internalNotification: !formData.internalNotification,
                })
              }
            />

            <FormControlLabel
              className="checkbox grey-lable mb2 pl3"
              control={
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                  }
                  checkedIcon={<img src="/images/chck.svg" alt="check" />}
                />
              }
              label="Send this notification to females only"
              value={formData.notificationToFemale}
              onChange={(evt) =>
                setformData({
                  ...formData,
                  notificationToFemale: !formData.notificationToFemale,
                })
              }
            />

            <FormControlLabel
              className="checkbox grey-lable mb2 pl3"
              control={
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlank style={{ fill: "transparent" }} />
                  }
                  checkedIcon={<img src="/images/chck.svg" alt="check" />}
                />
              }
              label="Send this notification to residents only"
              value={formData.notificationToResident}
              onChange={(evt) =>
                setformData({
                  ...formData,
                  notificationToResident: !formData.notificationToResident,
                })
              }
            />
          </Grid>
          <Grid
            item
            className="w-100 d-flex gap-2"
            sx={{
              flexDirection: { xs: "column", md: "row" }, // Change flex direction responsively
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} className="deal-link">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={eventData}
                value={
                  formData.offerLink
                    ? eventData.find((event) => event.id == formData.offerLink)
                    : null
                }
                onChange={(event, value) => {
                  setformData({
                    ...formData,
                    offerLink: value.id,
                    outletLink: null,
                    type: "DEAL",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Deal link" />
                )}
              />
            </Grid>
            <p className="text-center m-0 mt-2">OR</p>
            <Grid item xs={12} sm={12} md={6} lg={6} className="deal-link">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={outletData}
                value={
                  formData.outletLink
                    ? outletData.find(
                        (event) => event.id == formData.outletLink
                      )
                    : null
                }
                onChange={(event, value) => {
                  setformData({
                    ...formData,
                    offerLink: null,
                    outletLink: value.id,
                    type: "OUTLET",
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Listing link" />
                )}
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="flex relative w-100">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={data}
                sx={{ width: 300 }}
                value={formData.offerLink ? data.find((event) => event.id == formData.offerLink) : null}
                onChange={(event, value) => {
                  setformData({ ...formData, offerLink: value.id });
                }}
                renderInput={(params) => <TextField {...params} label="Select Deal link" />}
              />
            </div>
          </Grid> */}
          <div
            className="w-100"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              className="btn-primary notification-button w-30 mt-3"
              onClick={() => _submit()}
            >
              Submit
            </Button>
          </div>
        </Grid>
        <h1 className="header mt5 mb3">Sent & Pending Notifications</h1>
        <div className="mt4 flex items-center">
          <input
            type="text"
            className="inpt-width"
            placeholder="Search by notification or Deal"
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <div className="search flex justify-center items-center">
            <img src="/images/search.png" alt="search" onClick={handleSearch} />
          </div>
        </div>
        <div className="mt4 table-main">
          <TableContainer>
            <Table className="w-100" sx={{ minWidth: 991 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => requestSort("eventName")}
                    className={getClassNamesFor("eventName")}
                  >
                    Listing
                    {sortConfig ? (
                      sortConfig.key === "" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => requestSort("title")}
                    className={getClassNamesFor("title")}
                  >
                    Title{" "}
                    {sortConfig ? (
                      sortConfig.key === "title" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => requestSort("message")}
                    className={getClassNamesFor("message")}
                  >
                    Message{" "}
                    {sortConfig ? (
                      sortConfig.key === "message" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => requestSort("offer?.offerName")}
                    className={getClassNamesFor("offer?.offerName")}
                  >
                    Notification{" "}
                    {sortConfig ? (
                      sortConfig.key === "offer?.offerName" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => requestSort("notificationDate")}
                    className={getClassNamesFor("notificationDate")}
                  >
                    Send Date{" "}
                    {sortConfig ? (
                      sortConfig.key === "notificationDate" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )}
                  </TableCell>
                  <TableCell
                  // onClick={() => requestSort("notificationTime")}
                  // className={getClassNamesFor("notificationTime")}
                  >
                    Send Time{" "}
                    {/* {sortConfig ? (
                      sortConfig.key === "notificationTime" &&
                        (sortConfig.direction === "descending" ||
                          sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )} */}
                  </TableCell>
                  <TableCell
                    onClick={() => requestSort("isActive")}
                    className={getClassNamesFor("isActive")}
                  >
                    Status{" "}
                    {sortConfig ? (
                      sortConfig.key === "isActive" &&
                      (sortConfig.direction === "descending" ||
                        sortConfig.direction === undefined) ? (
                        <i className="fa-solid fa-sort-down ml"></i>
                      ) : (
                        <i className="fa-solid fa-sort-up ml"></i>
                      )
                    ) : (
                      <i className="fa-solid fa-sort ml"></i>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map((item, index) => (
                  <TableList
                    item={item}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    key={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
