import axios from "axios";
import { baseURL, storageToken } from "./constants";

const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((request) => {
  const authToken = window.localStorage.getItem(storageToken);
  request.headers.authorization = authToken ? `Bearer ${authToken}` : "";

  return request;
});

api.interceptors.response.use((response) => {
  const { headers } = response;

  if (headers.authorization) {
    window.localStorage.setItem(storageToken, headers.authorization);
  }

  return response;
});

export default api;

export const FILE_UPLOAD = "/file-upload";

export const USER_APIS = {
  adminUsers: "/users-admin",
  all: "/users/all",
  dashboard: "/dashboard/users",
  moderator: "/moderator",
  role: "/users/role",
  users: "/users",
  userDetails: "/user",
  search: "/users/search",
  forgetPasswrd: "/forgot-password?email=",
  updateSingleUsr: "/userupdate?id=",
};

export const USER_APIS_V1 = {
  all: "/v1/users/all"
};

export const CATEGORY = {
  all: "/categories",
};

export const VENUE_APIS = {
  vendor: "/venues/vendor",
  venues: "/venues",
  search: "/venues/search",
  vendorVenueListing: "/venues/vendor"
};

export const OUTLET_APIS = {
  outlets: "/outlets",
  outletsAll: "/outlets/admin",
  search: "/outlets/search",
  searchOutlet:"/outlets/searchOutlet"
};

export const EXPORT_APIS = {
  listing: "/export/admin?type=1",
  deal: "/export/admin?type=0"
}

export const EVENT_APIS = {
  events: "/events",
  search: "/events/search",
  admin:"eventsAdmin",
  updateOrder: "/update-order"
};

export const OFFERS_APIS = {
  offers: "/offers",
  updatePromotionalOffer: "v1/updatePromotionalOffer"
};

export const DASHBOARD_APIS = {
  dashboard: "/dashboard",
  // bar:'/dashboard/bars',
  brunch:'/dashboard/brunches',
  explore:'/dashboard/explore',
  restaurant:'/dashboard/restaurants',
  event:'/dashboard/events',
  outlet:'/featured/outlets',
  featuredEvent:'/featured/events',
  appUpdateSetting: "/appSetting",
  addFeaturedOutlet: "/featured",
  deleteFeaturedOutlet: "/featured"
};

export const REDEMPTIONS_APIS = {
  getAll: "/redemptions",
  // getAll: "/events/redemption/admin",
  getUser: "/redemptions/users/",
  search: "/redemptions/search",
  export: "/redemptions/exports"
};

export const Notification_APIS = {
  notifications: "/notifications",
  getAllNoti: "/notifications/getAll",
  events: "/v1/events"
};
export const REPORT_API = {
  report: "/report",
  venue: "/report/venue",
  getOfferByDate: "/report/getOfferByDate"
};

export const VENDOR_APIS = {
  all: "/vendors",
  search: "/vendors/search",
  register: "/vendors",
  checkEmail: "/vendors/check-email",
  payment: "/payment"
};


export const PLANS_APIS = {
  all: "/plans",
};